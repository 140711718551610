/* Slick slider styles */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    @extend %listreset;
    text-align: center;
    font-size:0;
    line-height:0;
    padding-top: 14px;
    margin: 0 -10px;

    @include media('>=tablet') {
        padding-top: 30px;
    }

    @include media('>=widescreen') {
        padding: 47px 0 0;
    }

    > *{
        letter-spacing: 0;
    }

    li {
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        @include media('>=desktop') {
            padding: 0 15px;
        }

        @include media('>=widescreen') {
            padding: 0 19px;
        }

        &.slick-active {
            button,
            a {
                background: #c4916b;

                @include media('>=tablet') {
                    opacity: 1;
                }
            }
        }
    }

    button,
    a {
        display: block;
        padding: 0;
        border: none;
        box-shadow: none;
        @include size(6px);
        border-radius: 50%;
        background: none;
        border: 1px solid #c4916b;



        &:hover{
            background: #c4916b;


        }

        @include media('>=tablet') {
            @include size(12px);
            border-radius: 50%;
        }
    }
}

.slick-arrow {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: 24px;
    line-height: 1;
    box-shadow: none;
    position: absolute;
    color: $orange;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);

    .icon-arrow {
        transform: rotate(180deg);
    }

    &.slick-prev {
        left: 0;

        .icon-arrow { 
            display: block;
        }
    }

    &.slick-next {
        right: 0;
    }
}

.slider {
    margin-bottom: 40px;
    padding: 0 30px;

    @include media('>=tablet') {
        margin-bottom: 80px;
    }

    @include media('>=desktop') {
        margin-bottom: 101px;
    }

    .slick-dots {
        padding: 0;
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;

        @include media('>=tablet') {
            bottom: -40px;
        }

        @include media('>=desktop') {
            bottom: -46px;
        }
    }

    img {
        max-width: none;
        width: 100%;
    }
}