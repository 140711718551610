
@font-face {
    font-family: 'optima';
    src: url('fonts/fontface/unicode.optima-webfont.woff2') format('woff2'),
         url('fonts/fontface/unicode.optima-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('fonts/fontface/montserrat-mediumitalic-webfont.woff2') format('woff2'),
         url('fonts/fontface/montserrat-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;

}

@font-face {
    font-family: 'montserrat';
    src: url('fonts/fontface/montserrat-light-webfont.woff2') format('woff2'),
         url('fonts/fontface/montserrat-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('fonts/fontface/montserrat-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/fontface/montserrat-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'montserratmedium';
    src: url('fonts/fontface/montserrat-medium-webfont.woff2') format('woff2'),
         url('fonts/fontface/montserrat-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}