/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */

/*wrap*/
$inner-wrap-l : 1200px;
$inner-wrap-m : 1140px;

/*nav*/
$nav-l-height: 60px;
$nav-m-height: 50px;
$nav-s-height: 40px;

$font-muli: 'Muli', 'Noto Sans TC', 'Noto Sans CJK TC', 'PinFang Tc', Arial, Helvetica, sans-serif; 


/* ------------------------------------
 *  Color
 * ------------------------------------ */
$white-light: #f2f2f2;

// -------------------------------------------------------
// custom variables and override bootstrap variables
// -------------------------------------------------------

$icon-email: "\e905";
$icon-address: "\e906";
$icon-download: "\e907";
$icon-postal: "\e90a";
$icon-half-arrow: "\e900";
$icon-arrow-up: "\e901";
$icon-bar: "\e902";
$icon-close: "\e903";
$icon-angle-left: "\e908";
$icon-angle-right: "\e909";
$icon-phone: "\e904";


$orange: #c4916b;
$gray: #6d6e71;
$black: #000;
$white: #fff;
$red: #b71832;
$green: #8dc93e;
$light-blue: #3b98de;
$blue: #2e6ebb;
$yellow: #f09529;
$light-red: #e73e3d;
$pink: #e61c73;
$light-gray: #c7c8ca;
$dark-gray: #a7a9ac;
$golden: #b89c6b;
$dark: #221e1f;


$gray: #121212;
$black: #000;
$white: #fff;
$light-blue: #268BDB;
$blue: #221DFF;

// font name
$font-optima: 'optima', 'Arial', 'Helvetica', sans-serif;
$font-mon: 'montserrat', 'Arial', 'Helvetica', sans-serif;
$font-family-sans-serif:$font-mon;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;



$font-path: "../../fonts";
$icomoon-font-path: "../../fonts/icons";
$image-path: "../../images";

$text-color: $gray;


// scaffolding
$body-bg: $white;
$body-color: $black;
$link-color: $orange;
$link-hover-color: darken($orange,20%);
$link-hover-decoration: none;

// typography
$font-size-base: 14px;
$line-height-base: 1.667;
$h1-font-size: 30px ;
$h2-font-size: 27px ;
$h3-font-size: 24px ;
$h4-font-size: 21px ;
$h5-font-size: 17px ;
$h6-font-size: 15px ;
$headings-font-weight: 400;
$headings-font-family:   $font-optima;

// container sizes
$grid-gutter-width: 30px;
$container-large-desktop: (1110px + $grid-gutter-width);

$btn-border-radius: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;




$border-radius-base: 0;
