#header {
	position: fixed;
	background: $black;
	left: 0;
	right: 0;
	top: 0;
	z-index: 9999;
	padding: 14px 0;

	.container {
		position: relative;
		max-width: 1266px;
	}
}



.logo {
	width: 83px;

	@include media('>=tablet') {
		width: 183px;
	}

	img {
		width: 100%;
		max-width: none;
	}
}

.nav-drop {
	position: fixed;
	z-index: 999;
	left: 0;
	right: 0;
	top: 61px;
	bottom: 0;
	overflow: hidden;
	max-height: 0;
	overflow: hidden;
	@include animate(max-height);

	@include media('>=tablet') {
		top: 100px;
	}

	.nav-active & {
		max-height: 9999px;
	}

	.nav-wrap {
		overflow-y: auto;
		background: $black;
		height: 100%;
		padding: 20px 0;
		transform: translateY(-100%);
		@include animate(transform);

		@include media('>=tablet') {
			padding: 0;
		}

		.nav-active & {
			transform: none;
		}
	}
}


.menu-list {
	@extend %listreset;
	text-align: center;
	@include font(20px,28px);
	font-style: italic;
	font-family: $font-optima;
	margin-bottom: 20px;

	@include media('>=tablet') {
		@include font(30px,38px);
		margin-bottom: 40px;
	}

	@include media('>=desktop') {
		@include font(44px,54px);
		letter-spacing: 1px;
		margin-bottom: 60px;
	}

	@include media('>=widescreen') {
		margin: 0 0 137px;
	}

	li {
		margin-bottom: 15px;

		@include media('>=desktop') {
			margin-bottom: 17px;
		}

		&.active {
			a {
				color: $orange;
			}
		}
	}

	a {
		color: $white;

		&:hover {
			color: $orange;
		}
	}
}

.nav-opener{
	display: block;
	position: absolute;
	right: 24px;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 40px;
	
	&:before,
	&:after,
	span{
		background: $white;
		position:absolute;
		top:50%;
		left:20%;
		right:20%;
		height:2px;
		margin-top:-2px;
		transition:all 0.2s linear;
	}
	&:before,
	&:after{
		content:'';
		top:30%;
	}
	&:after{
		top: 70%;
	}
	&:hover{
		opacity: 0.9;
	}

	.nav-active & {
		span{
			opacity: 0;
		}
		&:before,
		&:after{
			-moz-transform:rotate(45deg);
			-webkit-transform:rotate(45deg);
			-ms-transform:rotate(45deg);
			transform:rotate(45deg);
			top:50%;
			left:15%;
			right:15%;
		}
		&:after{
			-moz-transform:rotate(-45deg);
			-webkit-transform:rotate(-45deg);
			-ms-transform:rotate(-45deg);
			transform:rotate(-45deg);
		}
	}
}

.laguage-list {
	@extend %listreset;
	margin: 0 -10px;
	text-align: center;

	&.hidden-desktop {
		display: block;

		@include media('>=tablet') {
			display: none;
		}
	}


	&.hidden-mobile {
		display: none;
		
		@include media('>=tablet') {
			position: absolute;
			right: 90px;
			top: 50%;
			display: flex;
			transform: translateY(-50%);
			flex-wrap: wrap;
			bottom: auto;

			li {
				margin: 0 !important;
			}
		}
	}
	


	@include media('>=tablet') {
		right: 106px;
		bottom: 121px;
		position: fixed;
	}

	li {
		padding: 0 10px;
		display: inline-block;
		vertical-align: top;
		position: relative;

		@include media('>=tablet') {
			display: block;
			margin-bottom: 10px;
		}

		&.wpml-ls-item-ja {

			.wpml-ls-display:after  {
				content: '';
				background: url(images/jp.png) no-repeat 0 0; 
				width: 24px !important;
				height: 24px !important;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			.wpml-ls-flag {
				display: none !important;
			}

		}

		&.wpml-ls-item-en {

			.wpml-ls-flag {
				display: none !important;
			}


			.wpml-ls-display:after  {
				content: '';
				background: url(images/en.png) no-repeat 0 0; 
				width: 24px !important;
				height: 24px !important;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				
			}
		} 
	}

	
	.wpml-ls-menu-item  {
		.wpml-ls-flag {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.wpml-ls-display  {
			text-align: right;
			padding-right: 34px !important;
			position: relative;
			display: inline-block !important;
			margin-left: 0 !important;
			vertical-align: top !important;
			font-size: 16px !important;
			line-height: 1.417 !important;

			@include media('>=tablet') {
				
			}
		}

	}

	.img {
		display: inline-block;
		vertical-align: middle;
		margin-left: 6px;

		img {
			vertical-align: middle;
		}
	}

	a {
		display: block;
		line-height: 1;

		@include media('>=tablet') {
			text-align: right;
		}

		&:hover {
			color: $orange;
		}
	}

	.txt {
		display: inline-block;
		vertical-align: middle;
	}

	a {
		color: $white;
		text-transform: uppercase;
	}
}

.nav-active {
	overflow: hidden;
}

.resize-active * {
	overflow: hidden;
}