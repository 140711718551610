.banner {
	@include v-align(100%);
	background-size: cover;
	min-height: 300px;
	height: calc(100vh - 60px);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	color: $white;
	font-family: $font-optima;
	font-style: italic;
	position: relative;
	z-index: 2;
	padding: 20px 0;
	@include font(13px,18px);

	@include media('>=tablet') {
		height: calc(100vh - 99px);
		@include v-align(500px)
		@include font(18px,24px);
	}

	@include media('>=desktop') {
		@include v-align(638px);
		@include font(24px,30px);
	}

	@include media('>=widescreen') {
		@include font(36px,48px);
	}

	h1 {
		font-style: normal;
		margin: 0 0 4px;
	}
}

.info-block {
	padding: 55px  0 31px;
	text-align: center;
	position: relative;
	z-index: 1;

	@include media('>=tablet') {
		padding: 40px 0;
	}

	@include media('>=desktop') {
		padding: 85px  0 92px;
	}

	.heading {
		position: relative;
		padding-bottom: 30px;
		z-index: 1;

		@include media('>=tablet') {
			margin: 0;
			padding-bottom: 24px;
		}


		.icon-star {
			font-size: 192px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			text-align: center;
			z-index: -1;
			opacity: 0.05;
			pointer-events: none;

			@include media('>=tablet') {
				font-size: 250px;
			}

			@include media('>=desktop') {
				font-size: 508px;
			}
		}
	}

	h1 {
		margin: 0 0 33px;

		@include media('>=desktop') {
			margin: 0 0 53px;
		}

		
	}

	.icon-star {
		color: $black;
		font-size: 40px;
	}
}

.video {
	position: relative;
	z-index: 1;
	margin: 0 -20px 41px;

	@include media('>=tablet') {
		margin: 0 0 44px;
	}

	.btn-play {
		position: absolute;
		top: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		font-size: 48px;
		left: 50%;
		color: $black;

		@include media('>=tablet') {
			font-size: 62px;
		}

		&:hover {
			color: $orange;
		}
	}
}

#main {
	padding-top: 60px;

	@include media('>=tablet') {
		padding-top: 99px;
	}

}

.btn-holder {
	text-align: center;
}

.post-block {
	background: $black;
	color: $white;
	padding: 48px 0 16px;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 112px 0 0;
	}

	.heading {
		margin-bottom: -10px;

		@include media('<tablet') {
			padding: 0 25px;
		}

		@include media('>=tablet') {
			margin: 0 0 40px;
		}

		@include media('>=desktop') {
			margin: 0 0 70px;
		}

		@include media('>=widescreen') {
			margin: 0 0 126px;
		}


		h1 {
			margin: 0 0 40px;
		}
	}
}

.post-item {
	@include v-align(770px);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	position: relative;
	overflow: hidden;
	z-index: 1;
	margin-bottom: 8px;

	@include media('>=tablet') {
		margin-bottom: 30px;
	}

	@include media('>=desktop') {
		margin-bottom: 65px;
	}

	&:nth-child(2n+1) {
		.top {
			right: -2px;
			left: auto;
			transform: scale(1,-1);
		}

		.bottom {
			left: -2px;
			right: auto;
			transform: scale(-1,1);
		}
	}

	.top {
		position: absolute;
		left: -2px;
		top: -2px;
		width: 110%;
		transform: rotate(180deg);
		pointer-events: none;
		z-index: 2;

		@include media('>=phone') {
			width: 94%;
		}

		@include media('>=tablet') {
			width: 75%;
		}

		@include media('>=desktop') {
			width: 70%;
			
		}

		@include media('>=widescreen') {
			width: 53%;
		}

		img {
			max-width: none;
			width: 100%;
		}
	}

	.bottom {
		position: absolute;
		bottom: -2px;
		right: -2px;
		width: 110%;
		pointer-events: none;
		z-index: 2;

		@include media('>=phone') {
			width: 94%;
		}

		@include media('>=tablet') {
			width: 75%;
		}

		@include media('>=desktop') {
			width: 70%;	
		}

		@include media('>=widescreen') {
			width: 53%;
		}

		img {
			max-width: none;
			width: 100%;
		}
	}

	@include media('>=tablet') {
		padding: 100px 0;
		@include v-align(570px,bottom);
	}

	@include media('>=desktop') {
		padding: 73px 0;
		@include v-align(697px,bottom);
	}

	@include media('>=widescreen') {
		
	}

	&:nth-child(2n+1) {
		.post-box {
			margin: 0 0 0 auto;
		}
	}
}

.post-box {
	background: $black;
	color: $white;
	max-width: 237px;
	width: 100%;
	position: relative;
	z-index: 5;
	margin: 0 auto;
	text-align: left;
	padding: 43px 13px 43px 32px;

	@include media('>=tablet') {
		margin: 0;
		max-width: 362px;
		padding: 26px 12px 38px 76px;
		@include v-align(148px);
		text-align: left;
	}

	&.orange,
	&.gold {
		h2,
		.btn-more:after {
			color: #c4916b;
		}
	}

	&.blue {
		h2,
		.btn-more:after {
			color: #3395c9;
		}
	}

	&.green {
		h2,
		.btn-more:after {
			color: #8fa063;
		}
	}

	&.red {
		h2,
		.btn-more:after {
			color: #d95b34;
		}
	}


	a {
		color: $white;
	}

	h2 {
		font-style: italic;
		margin: 0 0 30px;

		@include media('>=desktop') {
			margin: 0 0 11px;
		}


	}
}

.white-section {
	padding: 62px 20px 87px;
	text-align: center;
	position: relative;
	z-index: 1;

	@include media('>=tablet') {
		padding: 80px 0;
	}

	@include media('>=desktop') {
		padding: 100px 0;
	}

	@include media('>=widescreen') {
		padding: 152px 0 158px;
	}

	h1 {
		margin: 0 0 60px;

		@include media('>=desktop') {
			margin: 0 0 45px;
		}

		
	}

	p {
		margin: 0 0 55px;

		@include media('>=widescreen') {
			margin: 0 0 75px;
		}
	}
}

.follow-block {
	background: $black;
	padding: 43px 0 14px;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 80px 0;
	}

	@include media('>=widescreen') {
		padding: 120px 0 1px;
	}

	.container {
		max-width: 1266px;
	}

	h1 {
		margin:  0 0 27px ;

		@include media('>=tablet') {
			margin: 0 0 35px;
		}

		@include media('>=desktop') {
			margin: 0 0 76px;
		}
	}
}

.block {
	margin: 0 -20px 33px;

	@include media('>=tablet') {
		margin: 0 -10px 33px;
		letter-spacing: -8px;

		> * {
			letter-spacing: 0;
		}
	}

	@include media('>=desktop') {
		margin: 0 -15px 33px;
	}

	@include media('>=widescreen') {
		margin: 0 -22px 33px;
	}

	@include media('>=1440px') {
		margin: 0 -44px 81px;
	}
}

.block-item {
	text-align: center;
	margin: 0 0 32px;

	@include media('>=tablet') {
		display: inline-block;
		vertical-align: top;
		width: 33.33%;
		padding: 0 10px;
		margin-bottom: 45px;
	}

	@include media('>=desktop') {
		padding: 0 15px;
		margin-bottom: 64px;
	}

	@include media('>=widescreen') {
		padding: 0 22px;
	}

	@include media('>=1440px') {
		padding: 0 44px;
	}

	.title {
		color: $orange;
		font: 700 20px/24px $font-optima;
		display: block;
		font-style: italic;
		padding-top: 13px;

		@include media('>=tablet') {
			text-align: left;
		}
	}

	a {
		position: relative;
		display: block;
		overflow: hidden;


		&:hover {
			.text-wrap {
				opacity: 1;
			}
		}
	}

	.text-wrap {
		position: absolute;
		left: 0;
		right: 0;
		display: block;
		bottom: 0;
		width: 100%;
		text-align: center;
		background-color: rgba(196, 145, 107, 0.9);
		color: $white;
		padding: 10px 10px;
		@include animate(background opacity);
		opacity: 0;

	}

	.txt {
		letter-spacing: 1px;
		text-decoration: none;
		font: italic 16px/24px $font-optima;
		display: inline-block;
		vertical-align: top;
		padding-right: 39px;
		color: $white;
		position: relative;

		&:after {
			@extend .icon-arrow:before;
			font: 24px/1 'icon';
			position: absolute;
			right: 0;
			top: 50%;
			color: $white;
			transform: translateY(-50%);
		}
	}


	@include media('<tablet') {
		img {
			max-width: none;
			width: 100%;
		}
	}
}

.text-block {
	position: relative;
	padding: 50px 10px 43px;
	text-align: center;
	font-weight: 300;


	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 80px 0;
	}

	@include media('>=widescreen') {
		padding: 110px 0 78px;
	}

	h1 {
		margin: 0 0 25px;

		@include media('>=desktop') {
			margin: 0 0 34px;
		}
	}
}

.milestone-img {
	margin: 0 -20px 31px;

	@include media('>=tablet') {
		margin: 0 0 40px;
	}

	@include media('>=desktop') {
		margin: 0 0 60px;
	}

	@include media('>=widescreen') {
		margin: 0 0 110px;
	}
}

.black-section {
	background: $black;
	color: $white;
	padding: 48px 0 42px;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 80px 0;
	}

	@include media('>=widescreen') {
		padding: 112px 0 1px;
	}

	.container {
		max-width: 1345px;
	}

	h1 {
		margin: 0 0 36px;

		@include media('>=desktop') {
			margin: 0 0 60px;
		}
	}
}

.year-block {
	margin: 0 0 56px;

	.btn-see {
		display: none;
	}
}

.year-list {
	padding: 0 5px;
	margin: 0 0 125px;
	position: relative;

	@include media('>=tablet') {
		letter-spacing: -8px;

		> * {
			letter-spacing: 0;
		}

		&:before {
			content: '';
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 1px;
			top: 0;
			bottom: 0;
			height: 100%;
			background: $orange;
		}
	}

	.year-item {

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			margin: 0;
		}


		&:nth-child(2n){ 
			
			@include media('>=tablet') {
				padding-top: 90px;
				padding-left: 30px;
			}

			@include media('>=desktop') {
				padding-top: 100px;
				padding-left: 57px;
			}

			h2 {
				&:before {


					@include media('>=tablet') {
						right: auto;
						left: -46px;
					}

					@include media('>=desktop') {
						right: auto;
						left: -57px;
						transform: translate(-50%, 50%);
					}
				}
			}
		}


	}


	ul {
		@extend %listreset;
		font-weight: 300;

		@include media('>=tablet') {
			line-height: 32px;
			padding-right: 20px;
		}

		li {
			margin-bottom: 14px;

			@include media('>=tablet') {
				margin-bottom: 1px;
			}
		}
	}

	h2 {
		color: $orange;
		font-style: italic;
		text-transform: uppercase;
		padding-bottom: 11px;
		margin: 0 0 10px;
		position: relative;

		@include media('>=tablet') {
			border: none;
			padding-right: 20px;
		}

		@include media('>=desktop') {
			@include font(44px,54px);
			padding-bottom: 9px;
			margin: 0 0 16px;
		}

		&:after {
			content: '';
			bottom: 0;
			left: 0;
			right: 0;
			background: $orange;
			height: 1px;
			position: absolute;

			@include media('>=tablet') {
				left: -30px;
			}

			@include media('>=desktop') {
				left: -57px;
			}
		}

		&:before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			@include size(8px);
			background: $orange;
			border-radius: 50%;
			transform: translateY(50%);

			@include media('>=tablet') {
				transform: translate(50%, 50%);
				@include size(16px);
			}
		}
	}
}

.team-block {
	padding: 0 10px;
	font-weight: 300;
	text-align: center;
	max-width: 1225px;
	margin: 0 auto;

	@include media('>=tablet') {
		padding: 0;
	}

	h1 {
		margin: 0 0 27px;
	}

	.img {
		margin: 0 -30px ;

		@include media('>=tablet') {
			margin: 0;
		}
	}

	.heading {
		margin:  0 auto 52px;

		@include media('>=tablet') {
			max-width: 980px;
		}
	}
}

.img {
	&.full {
		margin: 0 -20px;

		@include media('>=tablet') {
			margin: 0;
		}
	}
}

.team-list {
	@extend %listreset;
	text-align: left;
	padding: 50px 0 2px;


	@include media('>=tablet') {
		letter-spacing: -8px;
		margin: 0 -15px; 

		> * {
			letter-spacing: 0;
		}
	}

	h3 {
		@include font(16px,20px);
		margin: 0 0 13px;
		font-weight: 700;
		font-style: italic;


		@include media('>=desktop') {
			@include font(20px,24px);
			margin: 0 0 18px;
		}
	}

	li {
		margin: 0 0 53px;

		@include media('<tablet') {
			&:last-child  {
				margin: 0;
			}
		}

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			padding: 0 15px;
		}
	}
}

.award-block {
	text-align: center;
	padding: 49px 10px 39px;

	@include media('>=tablet') {
		padding: 50px 0;
	}

	@include media('>=desktop') {
		padding: 70px 0;
	}

	@include media('>=widescreen') {
		padding: 111px 0 77px;
	}

	.heading {
		margin: 0 0 54px;

		@include media('>=widescreen') {
			margin: 0 0 68px;
		}
	}

	h1 {
		margin: 0 0 28px;
	}
}

.award-list {
	@extend %listreset;
	letter-spacing: -8px;
	margin: 0 -10px 12px;

	@include media('>=tablet') {
		margin: 0 -10px 20px;
	}

	@include media('>=desktop') {
		margin: 0 -10px 52px;
	}

	> * {
		letter-spacing: 0;
	}

	a {
		display: block;

		&:hover {
			opacity: 0.8;
		}
	}

	li {
		display: inline-block;
		vertical-align: middle;
		width: 50%;
		margin: 0 0 20px;
		padding: 0 10px;

		@include media('>=tablet') {
			width: 25%;
		}
	}
}

.img-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -2px;

	@include media('>=tablet') {
		margin: 0 -10px;
	}

	@include media('>=desktop') {
		margin: 0 -16px;
	}

	.img-item {
		width: 50%;
		padding: 0 2px 5px;

		@include media('>=tablet') {
			width: 33%;
			padding: 0 10px 20px;
		}

		@include media('>=desktop') {
			padding: 0 16px 32px;
		}
	}

	.img-wrap {
		display: block;
		border: 1px solid $orange;

		&:hover {
			opacity: 0.8;
		}

		img {
			max-width: none;
			width: 100%;
		}
	}
}

.csr-block {
	background: $black;
	color: $white;
	padding: 50px 10px 0;
	font-weight: 300;
	text-align: center;

	@include media('>=tablet') {
		padding: 60px 0;
	}


	@include media('>=desktop') {
		padding: 80px 0;
	}

	@include media('>=widescreen') {
		padding: 109px 10px 99px;
	}

	h1 {
		margin: 0 0 26px;
	}

	.heading {
		margin: 0 0 51px;
	}

	.img {
		margin: 0 -30px;
	}
}

.solution-block {
	background: $black;
	color: $white;
	padding: 48px 0;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 87px 0;
	}

	.container {
		max-width: 1265px;
	}
}

.accordion {
	border-top: 1px solid $orange;

	.accordion-item {
		border-bottom: 1px solid $orange;



		&.active {
			.opener {
				.opener-txt {
					&:after {
						transform: translateY(-50%) rotate(180deg);
					}
				}
			}
		}
	}

	.opener {
		font: italic 20px/36px $font-optima;
		display: block;
		color: $orange;
		position: relative;
		text-transform: uppercase;
		padding: 21px 80px 21px 0;
		

		@include media('>=tablet') {
			@include font(35px,45px);
		}

		@include media('>=desktop') {
			@include font(44px,54px);
			padding: 58px 80px 50px 0;
		}

		.opener-txt {
			display: inline-block;
			vertical-align: top;

			@include media('>=tablet') {
				position: relative;
				min-width: 471px;
				padding-right: 45px;
			}

			&:after {
				@extend .icon-angle-down:before;
				@include animate(transform);
				font: 9px/1 'icon';
				position: absolute;
				right: 51px;
				top: 50%;
				transform: translateY(-50%);

				@include media('>=tablet') {
					right: 0;
					font-size: 17px;
				}
			}
		}

		.icon {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 35px;

			@include media('>=tablet') {
				width: 81px;
				right: 3px;
			}
		}
	}

	.slide {
		text-align: center;
		font-weight: 300;

		.slide-holder {

			@include media('>=tablet') {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding-bottom: 40px;
			}

			@include media('>=desktop') {
				padding-bottom: 60px;
			}

			@include media('>=widescreen') {
				padding: 0 0 99px;
			}
		}
	}

	.text {
		padding: 10px 0px 24px;

		@include media('>=tablet') {
			text-align: left;
			width: calc(100% - 366px);

		}

		@include media('>=desktop') {
			width: calc(100% - 376px);
		}

		@include media('>=widescreen') {
			width: calc(100% - 414px);
		}

		p {
			margin: 0 0 19px;
		}
	}

	.img-wrap {
		display: none;

		@include media('>=tablet') {
			display: block;
			width: 336px;
			margin-right: 30px;
		}

		@include media('>=desktop') {
			margin-right: 40px;
		}

		@include media('>=widescreen') {
			margin-right: 78px;
		}

		.img {
			max-width: 167px;
			margin: 0 auto 18px;
		}

		.title {
			display: block;
			font-size: 42px;
			letter-spacing: 6px;
			line-height: 50px;
			text-transform: uppercase;
			font-family: $font-optima;
		}

		.txt-title {
			text-transform: uppercase;
			display: block;
			font-size: 20px;
			letter-spacing: 6px;
			font-family: $font-optima;
			line-height: 42px;
		}
	}


	.btn-more {
		color: $white;
	}
}

.service-block {
	position: relative;
	z-index: 1;
	background: $black;
	text-align: center;
	color: $white;
	padding: 40px 0;

	@include media('>=tablet') {
		padding: 130px 0 105px;
	}

	@include media('>=desktop') {
		padding: 160px 0 105px;
	}

	@include media('>=widescreen') {
		padding: 257px 0 105px; 
	}

	.heading {
		margin: 0 0 30px;


		@include media('>=tablet') {
			margin: 0 0 40px;
		}
		@include media('>=widescreen') {
			margin: 0 0 89px;
		}
	}

	h1 {
		text-transform: uppercase;

		@include media('>=desktop') {
			margin: 0 0 30px;
		}
	}

	.heading {
		margin-bottom: 50px;
	}

	.icon-bg {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 280px;
		z-index: -1;
		pointer-events: none;
		opacity: 0.5;
	}
}



.slider-list {
	padding: 20px 0;

	@include media('>=desktop') {
		padding: 66px 0;
	}

	h2 {
		color: $orange;
		font-style: italic;

		@include media('>=tablet') {
			@include font(44px,54px);
			margin-bottom: 25px;
		}

		@include media('>=desktop') {
			margin: 0 0 32px;
		}
	}


	.slider-item {
		margin-bottom: 30px;

		@include media('>=desktop') {
			margin: 0 0 65px;
		}
	}
}

.slider {
	



	.slide {
		@include media('>=phone') {
			padding: 0 5px;
		}

		@include media('>=tablet') {
			padding: 0 10px;
		}

		@include media('>=desktop') {
			padding: 0 15px;
		}

		@include media('>=widescreen') {
			padding: 0 22px;
		}

		a {
			display: block;
		}
	}
}


.service-info {
	padding: 30px 0;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 80px 0 59px;
	}

	@include media('>=widescreen') {
		padding: 110px 0 59px;
	}

	h1 {
		color: $orange;
		margin-bottom: 40px;

		@include media('>=desktop') {
			margin: 0 0 60px;
		}
	}
}

.service-list {
	@extend %listreset;
	text-align: center;
	margin: 0 -20px;
	letter-spacing: -8px;

	@include media('>=tablet') {
		margin: 0 -10px;
	}

	@include media('>=desktop') {
		margin: 0 -15px;
	}

	@include media('>=widescreen') {
		margin: 0 -22px;
	}

	> * {
		letter-spacing: 0;
	}

	li {
		margin-bottom: 30px;

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			padding: 0 10px; 
		}

		@include media('>=tablet') {
			padding: 0 15px;
		}

		@include media('>=desktop') {
			padding: 0 22px;
		}
	}

	.service-holder {
		background: $black;
		display: block;
		color: $white;
		padding: 20px;
		margin-bottom: 19px;

		@include media('>=tablet') {
			padding: 30px 15px;
		}

		@include media('>=desktop') {
			padding: 47px 20px ;
		}
	}

	.img {
		max-width: 89px;
		margin: 0 auto 8px;
	}


	.title {
		display: block;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 3px;
		text-transform: uppercase;
		font-family: $font-optima;
		margin: 0 0 -2px;
	}

	.txt-title {
		text-transform: uppercase;
		display: block;
		font-size: 12px;
		letter-spacing: 4px;
		font-family: $font-optima;
		line-height: 22px;
	}

	h3 {
		color: $orange;
		font-style: italic;
		text-transform: uppercase;

		@include media('>=tablet') {
			text-align: left;
		}
	}
}


.stories-block {

	.container {

		@include media('<tablet') {
			padding: 0;
		}
	}
}

.stories-list {

	.stories-item {
		margin: 0 0 20px;

		a {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			display: block;
			position: relative;
			@include v-align(220px);
			margin-bottom: 58px;
			background-color: $black;

			@include media('>=tablet') {
				@include v-align(448px);
				text-align: center;

				&:hover {
					color: $white;
					.btn-wrap {
						opacity: 1;
					}
				}
			}

			.h2 {
				color: $orange;
				color: $white;
				margin: 0;
				font-style: italic;

				@include media('>=tablet') {
					@include font(44px,54px);
				}
			}

			.btn-wrap {
				position: absolute;
				text-align: center;
				top: 100%;
				@include animate(opacity);
				left: 0;
				display: block;
				right: 0;
				padding: 17px 15px;
				opacity: 0;

				@include media('>=tablet') {
					background-color: rgba(196, 145, 107, 0.9);
					bottom: 0;
					top: auto;
					padding: 17px 30px;
					text-align: right;
				}

				@include media('>=widescreen') {
					padding: 16px 129px;
				}
			}

			.btn-more {
				@include media('>=tablet') {
					color: $white;
				}
			}
		}
	}
}

.section-black {
	background: $black;
	color: $white;
	text-align: center;
	padding: 100px 0 1px;


	@include media('>=widescreen') {
		padding: 250px 0 55px;
	}

	.heading {
		margin: 0 0 126px;

		@include media('>=tablet') {
			margin-bottom: 60px;
		}

		@include media('>=desktop') {
			margin-bottom: 80px;
		}
		@include media('>=widescreen') {
			margin: 0 0 130px;
		}

		h1 {
			margin: 0 0 27px;

			@include media('>=desktop') {
				margin: 0 0 37px;
			}		
		}

		
	}

	.carousel-holder {
		margin-bottom: 65px;

		@include media('>=tablet') {
			margin:  0 0 15px;
		}

		@include media('>=1440px') {
			margin: 0 -105px 15px;
		}
	}

	.txt-wrap {
		text-align: left;
		text-align: center;
		margin-bottom: 32px;

		@include media('>=tablet') {
			margin-bottom: 40px;
		}
		

		@include media('>=desktop') {
			margin: 0 0 93px;
		}


		.title {
			font: 700 25px/30px $font-optima;
			display: block;
			color: $orange;
			margin: 0 0 17px;

			@include media('>=tablet') {
				@include font(20px,24px);
				font-style: italic;
			}
		}
	}
}

.info-item {
	margin-bottom: 79px;

	@include media('>=widescreen') {
		margin-bottom: 86px;
	}

	h2 {
		margin-bottom: 24px;
		color: $orange;
		@include font(28px,40px);

		@include media('>=tablet') {
			font-style: italic;
		}

		@include media('>=desktop') {

			@include font(44px,54px);
		}

		@include media('>=widescreen') {
			margin: 0 0 44px;
		}
	}
}



.story-list {
	@extend %listreset;
	letter-spacing: -8px;
	margin: 0 -20px;

	@include media('>=tablet') {
		margin: 0 -10px;
	}

	@include media('>=desktop') {
		margin: 0 -15px;
	}

	@include media('>=widescreen') {
		margin: 0 -22px;
	}

	> * {
		letter-spacing: 0;
	}

	li {
		margin-bottom: 30px;
		text-align: center;

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			padding: 0 10px; 
		}

		@include media('>=tablet') {
			padding: 0 15px;
		}

		@include media('>=desktop') {
			padding: 0 22px;
		}
	}

	.img {
		position: relative;
		margin: 0 0 18px;

		a {

			&:hover {
				.btn-wrap {
					opacity: 1;
				}
			}
		}

		img {
			max-width: none;
			width: 100%;
		}
	}

	.btn-wrap {
		padding: 18px 15px;
		opacity: 0;
		@include animate(opacity);
		position: absolute;
		left: 0;
		background-color: rgba(196, 145, 107, 0.9);
		right: 0;
		bottom: 0;
		width: 100%;

		.btn-more {
			line-height: 1;
			vertical-align: middle;
		}

	}

	h3 {
		color: $orange;
		font-style: italic;

		@include media('>=tablet') {
			text-align: left;
		}
	}
}


.global-block {
	padding: 65px 0 40px;
	text-align: center;

	@include media('>=tablet') {
		text-align: left;
	}

	@include media('>=desktop') {
		padding: 131px 0 189px;
	}
}

.logo-list {

	.logo-item {
		margin: 0 0 98px;

		@include media('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		@include media('>=widescreen') {
			margin: 0 0 133px;
		}

		&:last-child {
			margin: 0;
		}
	}

	.text {
		@include media('>=tablet') {
			width: calc(100% - 300px);
		}

		@include media('>=desktop') {
			width: calc(100% - 350px);
		}

		@include media('>=widescreen') {
			width: calc(100% - 495px);
			padding: 0 40px;
		}

		p {
			@include media('>=tablet') {
				margin: 0;
			}
		}
	}

	.img {
		max-width: 72%;
		margin: 0 auto 43px;

		@include media('>=tablet') {
			max-width: none;
			width: 250px;
			margin-bottom: 0;
			padding: 0 30px;
			text-align: center;
		}

		@include media('>=desktop') {
			width: 350px;
		}

		@include media('>=widescreen') {
			width: 495px;
		}
	}
}

.filter-holder {
	@include media('>=tablet') {
		float: left;
		width: 167px;
		margin-right: 15px;
		min-height: 1px;
		position: relative;
	}

	@include media('>=desktop') {
		margin-right: 30px;
	}

	@include media('>=widescreen') {
		margin-right: 49px;
	}

}

.tabset {
	@extend %clearfix;
	@extend %listreset;
	text-align: center;
	font-size:0;
	line-height:0;
	margin: 0 0 24px;
	white-space: nowrap;
	overflow: hidden;
	background: $white;
	overflow-x: auto;
	z-index: 99;
	right: 0;
	position: absolute;
	left: 0;
	top: 0;

	

	@include media('>=tablet') {
		white-space: normal;
		overflow: visible;
		
		display: block;
		margin: 0;
	}

	
	li {
		
		padding: 10px 10px;
		display: inline-block;
		vertical-align: top;
		font: 20px/1.2 $font-optima;

		@include media('>=tablet') {
			padding: 0;
			margin-bottom: 20px;
			display: block;
			@include font(36px,40px);
		}

		@include media('>=desktop') {
			margin-bottom: 30px;
		}

		@include media('>=widescreen') {
			margin-bottom: 48px;
		}

		&:active  {
			a {
				color: $orange;
			}
		}
	}

	a {
		color: $black;

		&:hover,
		&.active {
			color: $orange;
		}
	}
}

.news-block {
	padding: 90px 7px 20px;
	position: relative;

	@include media('>=tablet') {
		padding: 40px 0;
	}

	@include media('>=desktop') {
		padding: 70px 0;
	}

	@include media('>=widescreen') {
		padding: 108px 0 78px;
	}

	.container {
		@extend %clearfix;
	}

	.tab {
		margin: 0 0 34px;

		@include media('>=tablet') {
			display: none;
			margin: 0;

			&.active {
				display: block;
			}
		}
	}

	.tab-content {
		overflow: hidden;
		position: relative;
	}
}

.date-title {
	font: 20px/24px $font-optima;
	display: block;
	margin: 0 0 9px;

	@include media('>=tablet') {
		display: none;
	}
}

.news-block {

	.news-item {
		@include media('>=tablet') {
			margin: 0 0 40px;
		}
	}

	.img {
		margin: 0 0 53px;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		height: 265px;

		@include media('>=tablet') {
			height: 300px;
			margin: 0 0 30px;
		}

		@include media('>=desktop') {
			height: 464px;
		}
	}

	.title {
		font: 700 16px/20px $font-optima;
		color: $orange;
		font-style: italic;
		margin: 0 0 16px;
		display: block;

		@include media('>=tablet') {
			margin: 0 0 9px;
			@include font(20px,24px);
		}

		a {
			color: $orange;

			&:hover {
				color: $black;
			}
		}
	}
}

.form {
	@extend %clearfix;
}

.filter-form {
}


.filter-btns {
	li {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			@include animate(color);

			&:hover {
				color: $orange;
			}
		}

		input {
			position: absolute;
			left: 0;
			right: 0;
			opacity: 0;
			visibility: hidden;

			&:checked {
				~ span {
					color: $orange;
				}
			}
		}
	}

	.filter-reset {
		margin: 0;
		padding: 0;
		background: none;
		border: none;

		&:hover {
			color: $orange;
		}

		&.active {
			color: $orange;
		}

	}
}

.news-wrap {
	overflow: hidden;
}

.img-content {
	margin: 0 0 20px;
	padding-top: 30px;

	@include media('>=tablet') {
		padding-top: 50px;
		margin: 0 0 32px;
	}

	@include media('>=desktop') {
		padding-top: 80px;
	}

	@include media('>=widescreen') {
		padding-top: 112px;
	}
}

.container {
	.container {
		padding: 0;
	}
}

.career-slider {
	letter-spacing: -8px;

	@include media('>=tablet') {
		margin: 0 -10px ;
	}

	@include media('>=desktop') {
		margin: 0 -15px ;
	}

	@include media('>=widescreen') {
		margin: 0 -23px ;
	}

	> * {
		letter-spacing: 0;
	}


	.slide {
		display: inline-block;
		vertical-align: top;
		width: 50%;

		@include media('>=tablet') {
			padding: 0 10px 20px;
		}

		@include media('>=desktop') {
			padding: 0 15px 30px;
		}

		@include media('>=widescreen') {
			padding: 0 -23px 32px;
		}
	}
}

.job-block {
	background: $black;	
	padding: 49px 0;
	color: $white;
	text-align: center;

	@include media('>=tablet') {
		padding: 60px 0;
	}

	@include media('>=desktop') {
		padding: 80px 0;
	}

	@include media('>=desktop') {
		padding: 104px 0 43px;
	}

	h1 {
		margin: 0 0 28px;

		@include media('>=desktop') {
			margin: 0 0 69px;
		}
	}
}

.select-form {
	margin: 0 auto 40px;
	max-width: 483px;

	@include media('>=desktop') {
		margin: 0 auto 60px;
	}
}

.job-list {
	@extend %listreset;
	font-size:0;
	line-height:0;
	padding: 0 20px;
	margin: 0 -10px 15px;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	justify-content: space-between;

	@include media('>=desktop') {
		margin: 0 -10px 50px;
	}
	li {
		@include media('>=tablet') {
			width: 33.33%;
			margin-bottom: 50px;
		}
	}


	a {
		display: inline-block;
		vertical-align: top;
	}

	li {
		font: 400 20px/36px $font-optima;
		font-style: italic;
		margin: 0 0 15px;		
		padding: 0 10px;
		width: 100%;

		@include media('>=tablet') {
			width: 33.33%;
		}

		@include media('>=desktop') {
			margin-bottom: 30px;
		}

		@include media('>=widescreen') {
			margin-bottom: 50px;
		}

		&.active {
			a {
				color: $orange;
			}
		}

		@include media('>=desktop') {
			@include font(36px,42px);
		}
	}

	a {
		color: $white;

		&:hover {
			color: $orange;

			.country {
				color: $white;
			}
		}

		.country {
			color: $orange;
			display: block;
		}
	}
}

.contact-block {
	padding: 31px 0;
	margin: 0 -20px;

	@include media('>=tablet') {
		margin: 0;
		padding: 33px 0;
	}

	address {
		line-height: 2;
	}

	.img {
		@include media('>=desktop') {
			width: 50%;
			float: left;
		}

		@include media('>=widescreen') {
			width: 48.3%;
		}

		img {
			max-width: none;
			width: 100%;
		}
	}

	.text {
		padding: 25px 20px;

		@include media('>=desktop') {
			overflow: hidden;
			padding: 0;
			padding-left: 30px;
		}

		@include media('>=widescreen') {
			padding-left: 42px;
		}
	}

	.title {
		font: italic 16px/20px $font-optima;
		display: block;
		color: $orange;
		text-transform: uppercase;
		margin: 0 0 27px;

		@include media('>=tablet') {
			@include font(20px,24px);
		}

		@include media('>=widescreen') {
			margin: 0 0 57px;
		}
	}

	.address-list {
		@extend %listreset;

		@include media('>=tablet') {
			padding-top: 5px;
		}

		@include media('>=desktop') {
			padding: 10px 0 0;
		}

		li {
			display: block;
			margin-bottom: 5px;

			@include media('>=desktop') {
				margin: 0 0 11px;
			}
		}
	}
}

.contact-item {
	@extend %clearfix;

	@include media('>=tablet') {
		margin-bottom: 30px;
	}

	@include media('>=desktop') {
		margin: 0 0 61px;
	}
}

.categories-item + .categories-item {
	&:before {
		content: ',';
		display:inline-block;
		vertical-align:bottom;
		margin-right: 4px;
	}
} 