body, html {
	background-color: #fff;
	-webkit-tap-highlight-color: rgba(0,0,0,.5);
}



/*basic element*/
a {
	color: inherit;
	text-decoration: none;
}

i {
	display: inline-block;
	vertical-align: middle;
}
