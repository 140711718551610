body {
	min-width: 320px;
	font-weight: 300;

	@include media('>=tablet') {
		@include font(16px,2);
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: none;
}

#wrapper {
	overflow: hidden;
	position: relative;
}

.container {
	width: 100%;
	max-width: 1052px;
	padding: 0 20px;

	&.container--big {
		max-width: 1275px;
	}
}


body {
	min-width: 320px;
}

p {
	margin: 0 0 1.2em;
}

a {
	@include animate(color opacity visibility background box-shadow border);
	outline: none;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $orange;
	}
}

button,
input[type="submit"] {
	@include animate(color opacity visibility background box-shadow border);
	outline: none;
}

h1,
.h1 {
	@include font(28px,35px);
	font-weight: 400;
	letter-spacing: 0.93px;
	margin: 0 0 15px;

	@include media('>=tablet') {
		@include font(35px,45px);
	}

	@include media('>=desktop') {
		@include font(50px,60px);
	}

	@include media('>=widescreen') {
		@include font(72px,88px);
		letter-spacing: 2.4px;
		margin: 0 0 16px;
	}
}

.orange {
	color: #c4916b;
}

h2,
.h2 {
	margin: 0 0 10px;
	@include font(20px,31px);

	@include media('>=tablet') {
		@include font(30px,40px);
	}

	@include media('>=desktop') {
		@include font(36px,53px);
		margin: 0 0 16px;
	}
}

h3,
.h3 {
	margin: 0 0 15px;
	@include font(16px,24px);
}

h4,
.h4 {
	@include font(20px,31px);

	@include media('>=desktop') {
		@include font(36px,42px);
	}
}

h4,
.h4 {
	margin: 0 0 15px;
	@include font(20px,31px);
}

h5,
.h5 {
	font-size: 20px;
	line-height: 36px;
	line-height: 0.45px;
}


.btn-more {
	letter-spacing: 1px;
	text-decoration: none;
	font: italic 16px/24px $font-optima;
	display: inline-block;
	vertical-align: top;
	padding-right: 39px;
	color: $black;
	position: relative;

	&.white {
		color: $white;
	}


	&:hover {
		&:after {
			transform: translate(5px, -50%);
		}
	}

	&:after {
		@include animate(transform);
		@extend .icon-arrow:before;
		font: 24px/1 'icon';
		position: absolute;
		right: 0;
		top: 50%;
		color: #c4916b;
		transform: translateY(-50%);
	}

	&.btn-white {
		color: $white;
	}
}

.btn-see {
	font: italic 16px/24px $font-optima;
	display: inline-block;
	vertical-align: top;
	@extend .icon-angle-down;
	position: relative;
	padding-top: 19px;

	&:before {
		font: 10px/1 'icon';
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		color: $orange;
	}
}