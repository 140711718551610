#footer {
	background: $black;
	text-align: center;
	padding: 26px 0;
	color: $white;

	@include media('>=widescreen') {
		padding: 58px 0;
	}

	.container {
		@extend %clearfix;
		max-width: 1266px;
	}

	
}

.footer-logo {
	width: 92px;
	margin: 0 auto 15px;

	@include media('>=tablet') {
		width: 170px;
		margin: 0;
		float: left;
	}

}

.footer-block {

	@include media('>=tablet') {
		overflow: hidden;
		padding-right: 170px;
		text-align: center;
	}
}

.copyright {
	font-weight: 500;
	display: block;
	margin-bottom: 10px;

	@include media('>=tablet') {
		font-weight: 300;
	}
}

.footer-nav {
	@extend %listreset;
	font-weight: 500;
	display: flex;
	flex-direction: column-reverse;
	text-align: center;
	margin-bottom: -10px;

	@include media('>=tablet') {
		display: block;
		letter-spacing: -8px;
		font-weight: 300;

		> * {
			letter-spacing: 0;
		}
	}

	@include media('>=desktop') {
		margin: 0 -20px;
	}

	li {
		margin-bottom: 10px;

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: middle;
			padding: 0 10px;

			+ li {
				position: relative;

				&:before {
					content: '|';
					position: absolute;
					left: 0;
					margin-top: -2px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		@include media('>=desktop') {
			padding: 0 20px;
		}
	}
}

.background {
	position: absolute;
	top: 50%;
	left: 50%;
	color: #000;
	transform: translate(-50%, -50%);
	opacity: 0.05;
	font-size: 240px;
	pointer-events: none;

	@include media('>=tablet') {
		font-size: 250px;
	}

	@include media('>=desktop') {
		font-size: 508px;
	}
}

.image-block {
	height: 0;
	background-size: cover;
	background-position: 50% 0%;
	background-repeat: no-repeat;
	padding-top: 41.65%;


	@include media('>=widescreen') {
		
		padding: 0;
		height: 600px;
	}

	img {
		max-width: none;
		width: 100%;
	}
}