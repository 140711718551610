.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: $black;
  color: $white;
  text-align: center;
  border-radius: 4px;
  border: solid 1px $orange;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: $font-optima;
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  height: 44px;
  line-height: 42px;
  outline: none;
  text-align: center !important;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-style: italic;
  width: auto;

  @include media('>=desktop') {
      font-size: 35px;
      height: 80px;
      line-height: 78px;
      padding: 0 78px;
  }

  @include media('>=widescreen') {
   font-size: 44px;
   height: 80px;
   line-height: 78px;
   
} 


}

.nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
    .nice-select:after {
       font: 8px/1 'icon';
       color: $orange;
       @extend .icon-angle-down:before;
       position: absolute;
       right: 15px;
       top: 50%;
       transform: translateY(-50%);

       @include media('>=desktop') {
           font-size: 15px;
           right: 24px;
       }
   }
   .nice-select.open:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg); }
    .nice-select.open .list {
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0); }
        .nice-select.disabled {
            border-color: #ededed;
            color: #999;
            pointer-events: none; }
            .nice-select.disabled:after {
              border-color: #cccccc; }
              .nice-select {
                width: 100%; }
                .nice-select .list {
                  left: 0 !important;
                  right: 0 !important; }
                  .nice-select.right {
                    float: right; }
                    .nice-select.right .list {
                      left: auto;
                      right: 0; }
                      .nice-select.small {
                        font-size: 12px;
                        height: 36px;
                        line-height: 34px; }
                        .nice-select.small:after {
                          height: 4px;
                          width: 4px; }
                          .nice-select.small .option {
                              line-height: 34px;
                              min-height: 34px; }
                              .nice-select .list {
                                padding: 16px 0;
                                background-color: #fff;
                                color: $black;
                                border-radius: 4px;
                                box-sizing: border-box;
                                border: solid 1px $orange;
                                opacity: 0;
                                overflow: hidden;
                                padding: 0;
                                pointer-events: none;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                -webkit-transform-origin: 50% 0;
                                -ms-transform-origin: 50% 0;
                                transform-origin: 50% 0;
                                -webkit-transform: scale(0.75) translateY(-21px);
                                -ms-transform: scale(0.75) translateY(-21px);
                                transform: scale(0.75) translateY(-21px);
                                -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
                                transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
                                z-index: 9; }
                                .nice-select .list:hover .option:not(:hover) {
                                  background-color: transparent !important; }
                                  .nice-select .option {
                                    cursor: pointer;
                                    font-weight: 400;
                                    line-height: 40px;
                                    list-style: none;
                                    min-height: 40px;
                                    outline: none;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 24px;
                                    font-family: $font-optima;
                                    padding: 4px 26px;

                                    -webkit-transition: all 0.2s;
                                    transition: all 0.2s; }
                                    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
                                       color: $orange;}
                                       .nice-select .option.selected {
                                          color: $orange; }
                                          .nice-select .option.disabled {
                                              background-color: transparent;
                                              color: #999;
                                              cursor: default; }

                                              .no-csspointerevents .nice-select .list {
                                                  display: none; }

                                                  .no-csspointerevents .nice-select.open .list {
                                                      display: block; }
