/* custom range input styles */

.jcf-range {
    display: inline-block;
    min-width: 200px;
    width: 100%;
    margin-right: -9px;
}

.jcf-range .jcf-range-track {
    margin: 0 20px 0 0;
    position: relative;
    display: block;
}

.jcf-range .jcf-range-wrapper,
.ui-slider  {
    background: #D8D8D8;
    border-radius: 5px;
    display: block;
    margin: 9px 0;
    position: relative;
    height: 2px;
}

.jcf-range.jcf-vertical {
    width: auto;
}

.jcf-range.jcf-vertical .jcf-range-wrapper {
    margin: 0;
    width: 10px;
    height: auto;
    padding: 20px 0 0;
}

.jcf-range.jcf-vertical .jcf-range-track {
    height: 180px;
    width: 10px;
}

.jcf-range.jcf-vertical .jcf-range-handle {
    // left: -5px;
    // top: auto;
}

.jcf-range .jcf-range-handle,
.ui-slider-handle {
    position: absolute;
    border: 2px solid #221DFF;
    background: $white;
    border-radius: 50%;
    outline: none;
    width: 18px;
    height: 18px;
    margin-top: -8px;
    z-index: 1;
    top: 0;
    left: 0;


    &.jcf-index-2 {
        margin-left: 3px;
    }
}
.jcf-range-mid,
.ui-slider-range {
    height: 2px;
    background: #221DFF;
    position: absolute;
    top: 0;
}

.jcf-range .jcf-range-mark {
    position: absolute;
    overflow: hidden;
    background: #000;

    width: 1px;
    height: 3px;
    top: -7px;
    margin: 0 0 0 9px;
}

.jcf-range.jcf-vertical .jcf-range-mark {
    margin: 0 0 9px;
    left: 14px;
    top: auto;
    width: 3px;
    height: 1px;
}

.jcf-range.jcf-focus .jcf-range-handle {
    margin-left: -1px;
}

.jcf-range.jcf-disabled {
    background: none !important;
    opacity: 0.3;
}


/* common custom form elements styles */

.jcf-disabled {
    background: #ddd !important;
}

