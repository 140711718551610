// ---------------------------------------------------------
// Custom mixins
// ---------------------------------------------------------

@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 1px;
    margin: 0 0 0 -5px;
    min-height: $va-height;
  }

  > * {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
  
  }
}

@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto ) {
  display: table;
  .#{$child-name1}{
    display: table-cell;
    vertical-align: $valign;
    width: $width1; 
  }
  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}


// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/// font-smothing
@mixin font-smoothing($value:on){
  @if $value == on{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
  @else{
    -webkit-font-smoothing:subpixel-antialiased;
    -moz-osx-font-smoothing:auto;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}


/// Creates a visual triangle. 
/// Mixin takes ($size, $color, $direction)
@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}


/// Animate css properties
// usage 
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: .3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($pxSize) {
  font-size: $pxSize;
  font-size: ($pxSize / 10px) + rem;
}

//font mixin
@mixin font($font-size, $line-height, $font-weight:normal) {
  font-size:$font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

// flex
@mixin flex($justify: space-between) {
  display: flex;
  flex-wrap: wrap;
  justify-content: $justify;
}

// sprite
@mixin sprite($x-pos:0, $y-pos:0) {
  @extend %sprite;
  background-position: $x-pos $y-pos;
}

// custom gutter
@mixin custom-gutter($gutter: 30px) {
  @extend %clearfix;
  margin: 0 -#{$gutter/2};

  [class*="col-"],
  [class^="col-"] {
    padding: 0 $gutter/2;
  }
}


// retina image
// @include retina(sprite, png, 500px, 350px);
@mixin retina($image, $extension, $width, $height) {
  background: url('../images/' + $image + '.' + $extension) no-repeat;
  width: $width;
  height: $height;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {    
    background-image: url('../images/' + $image + '@2x' + '.' + $extension);
    background-size: $width $height;
  }
}


// retina sprite
// @include sprite-retina(sprite, png, 500px, 350px, -45px, -197px);
@mixin sprite-retina($image, $extension, $width, $height, $el-width, $el-height, $horizontal, $vertical) {
  background: url('../images/' + $image + '.' + $extension) no-repeat $horizontal $vertical;
  width: $el-width;
  height: $el-height;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url('../images/' + $image + '@2x' + '.' + $extension);
    background-size: $width $height;
  }
}


// @font-face code generator
// *** demo ***
// @include font-face("font-name", ("../fonts/font-file-name"), normal, normal );
@mixin font-face($font, $url, $weight: normal, $style: normal){
  @font-face {
    font-family: $font;
    src:url('#{$url}.eot?5sv48p');
    src:url('#{$url}.eot?#iefix5sv48p') format('embedded-opentype'),
    url('#{$url}.svg?5sv48p') format('svg'),
    url('#{$url}.ttf?5sv48p') format('truetype'),
    url('#{$url}.woff?5sv48p') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}


// set font
// @include font(15px, 1.333, 500, italic);
// @include font(15px, 1.333, 500);
// @include font(15px, 1.333);
@mixin font($font-size, $line-height, $font-weight: null, $font-style: null, $family: "") {
  @if ($family != "") {
    font: $font-style $font-weight #{$font-size}/#{$line-height} $family;
  } @else {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    font-style: $font-style;
  }
}


// table-layout creation
// @include table-layout(bottom);
@mixin table-layout($align: middle) {
  display: table;
  width: 100%;
  table-layout: fixed;

  > * {
    display: table-cell;
    vertical-align: $align;
  }
}


// Outputs the spec and prefixed versions of the `::selection` pseudo-element.
// @param {Bool} $current-selector [false]
// If set to `true`, it takes the current element into consideration.
// usage:
// @include selection {
//   background-color: #f00;
// }
// .element {
//   @include selection(true) {
//     background-color: #000;
//     color: #fff;
//   }
// }

@mixin selection($current-selector: false) {
  @if $current-selector {
    &::-moz-selection {
      @content;
    }

    &::selection {
      @content;
    }
  } @else {
    ::-moz-selection {
      @content;
    }

    ::selection {
      @content;
    }
  }
}


// Provides a quick method for targeting `border-radius` on both corners on the side of a box.
//
// @param {Number} $radii
//   List of arguments
//
// @example scss - Usage
//   .element-one {
//     @include border-top-radius(5px);
//   }
//
//   .element-two {
//     @include border-left-radius(3px);
//   }
//
// @example css - CSS Output
//   .element-one {
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
//   }
//
//   .element-two {
//     border-bottom-left-radius: 3px;
//     border-top-left-radius: 3px;
//   }
//
// @output `border-radius`

@mixin border-top-radius($radii) {
  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-right-radius($radii) {
  border-bottom-right-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-bottom-radius($radii) {
  border-bottom-left-radius: $radii;
  border-bottom-right-radius: $radii;
}

@mixin border-left-radius($radii) {
  border-bottom-left-radius: $radii;
  border-top-left-radius: $radii;
}


// display: inline-block; extra space removing
// usage: @include dib-reset;
// @include dib-reset(middle);
@mixin dib-reset($align:top) {
  letter-spacing: -5px;
  > * {
    display: inline-block;
    vertical-align: $align;
    letter-spacing: 0;
  }
}

@mixin display($align:top) {
 display: inline-block;
 vertical-align: $align;
}

/// Directional-property mixins are shorthands for writing properties like the following
///
/// @ignore You can also use `false` instead of `null`.
///
/// @param {List} $vals
///   List of directional values
///
/// @example scss - Usage
///   .element {
///     @include border-style(dotted null);
///     @include margin(null 0 10px);
///   }
///
/// @example css - CSS Output
///   .element {
///     border-bottom-style: dotted;
///     border-top-style: dotted;
///     margin-bottom: 10px;
///     margin-left: 0;
///     margin-right: 0;
///   }
///
/// @require {function} contains-falsy
///
/// @return {List}

@function collapse-directionals($vals) {
  $output: null;

  $a: nth($vals, 1);
  $b: if(length($vals) < 2, $a, nth($vals, 2));
  $c: if(length($vals) < 3, $a, nth($vals, 3));
  $d: if(length($vals) < 2, $a, nth($vals, if(length($vals) < 4, 2, 4)));

  @if $a == 0 { $a: 0; }
  @if $b == 0 { $b: 0; }
  @if $c == 0 { $c: 0; }
  @if $d == 0 { $d: 0; }

  @if $a == $b and $a == $c and $a == $d { $output: $a;          }
  @else if $a == $c and $b == $d         { $output: $a $b;       }
  @else if $b == $d                      { $output: $a $b $c;    }
  @else                                  { $output: $a $b $c $d; }

  @return $output;
}

/// Output directional properties, for instance `margin`.
///
/// @access private
///
/// @param {String} $pre
///   Prefix to use
/// @param {String} $suf
///   Suffix to use
/// @param {List} $vals
///   List of values
///
/// @require {function} collapse-directionals
/// @require {function} contains-falsy

@mixin directional-property($pre, $suf, $vals) {
  // Property Names
  $top:    $pre + "-top"    + if($suf, "-#{$suf}", "");
  $bottom: $pre + "-bottom" + if($suf, "-#{$suf}", "");
  $left:   $pre + "-left"   + if($suf, "-#{$suf}", "");
  $right:  $pre + "-right"  + if($suf, "-#{$suf}", "");
  $all:    $pre +             if($suf, "-#{$suf}", "");

  $vals: collapse-directionals($vals);

  @if contains-falsy($vals) {
    @if nth($vals, 1) { #{$top}: nth($vals, 1); }

    @if length($vals) == 1 {
      @if nth($vals, 1) { #{$right}: nth($vals, 1); }
    } @else {
      @if nth($vals, 2) { #{$right}: nth($vals, 2); }
    }

    @if length($vals) == 2 {
      @if nth($vals, 1) { #{$bottom}: nth($vals, 1); }
      @if nth($vals, 2) { #{$left}:   nth($vals, 2); }
    } @else if length($vals) == 3 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 2) { #{$left}:   nth($vals, 2); }
    } @else if length($vals) == 4 {
      @if nth($vals, 3) { #{$bottom}: nth($vals, 3); }
      @if nth($vals, 4) { #{$left}:   nth($vals, 4); }
    }
  } @else {
    #{$all}: $vals;
  }
}

/// Provides a quick method for targeting `padding` on specific sides of a box. Use a `null` value to “skip” a side.
///
/// @param {Arglist} $vals
///   List of arguments
///
/// @example scss - Usage
///   .element {
///     @include padding(12vh null 10px 5%);
///   }
///
/// @example css - CSS Output
///   .element {
///     padding-bottom: 10px;
///     padding-left: 5%;
///     padding-top: 12vh;
///   }
///
/// @require {mixin} directional-property
///
/// @output `padding`


@mixin padding($vals...) {
  @include directional-property(padding, false, $vals...);
}

/// Provides a quick method for targeting `margin` on specific sides of a box. Use a `null` value to “skip” a side.
///
/// @param {Arglist} $vals
///   List of arguments
///
/// @example scss - Usage
///   .element {
///     @include margin(null 10px 3em 20vh);
///   }
///
/// @example css - CSS Output
///   .element {
///     margin-bottom: 3em;
///     margin-left: 20vh;
///     margin-right: 10px;
///   }
///
/// @require {mixin} directional-property
///
/// @output `margin`

@mixin margin($vals...) {
  @include directional-property(margin, false, $vals...);
}



@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}